<template>
 <div>
  <PbxHeader
   v-model:search="search"
   pbxMenuType="queueDetail"
   :periodSelector="period"
   :parseDateRange="parseDateRange"
   @select-hostname="selectHostname = $event"
   :selectedQueue="selectedQueue"
  />
  <div class="mx-5" v-if="didNumbers">
   <div class="md:pl-0 pl-10 pr-10 pt-5">
    <PbxDashboardSelector
     @selector="period = $event"
     @button-selector="(...args) => getSelectedButton(...args)"
     @action="$emit('action', $event), (selectedElement = $event), getDidNumberCallersObj($event)"
     :selectHostname="selectHostname"
     :didNumbers="didNumbers"
     @selectTop="showTop = $event"
     :selectedTop="showTop"
     :buttonSelected="this.buttonSelected"
    />
   </div>
  </div>
  <div class="flex flex-col" v-if="trtcData && trtcData.data.length > 0">
   <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
    <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-5">
     <div class="shadow overflow-hidden border-b border-gray-200 rounded-md">
      <TRTCTable
       :total="trtcData.total"
       :tablePaginatedData="filteredData()"
       :selectedElement="selectedElement"
       :showTop="showTop"
      />
     </div>
    </div>
   </div>
  </div>
  <div v-if="didNumbers.length <= 0" class="pt-10">
   <div class="text-center">
    <svg
     xmlns="http://www.w3.org/2000/svg"
     class="mx-auto h-12 w-12 text-gray-400"
     fill="none"
     viewBox="0 0 24 24"
     stroke="currentColor"
    >
     <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M21 3l-6 6m0 0V4m0 5h5M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
     />
    </svg>
    <h3 class="mt-2 text-lg font-medium text-gray-900">
     {{ $t("noCallersForThisPeriod") }}
    </h3>
   </div>
  </div>
 </div>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage"
 />
</template>

<script>
import axios from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import TablePagination from "../../components/TablePagination.vue";
import PbxHeader from "../../components/PBX/PbxHeader.vue";
import PbxDashboardSelector from "../../components/PBX/PbxDashboardSelector.vue";
import TRTCTable from "../../components/PBX/TRTCTable.vue";
import { mapGetters } from "vuex";

export default {
 components: {
  Loading,
  PbxHeader,
  PbxDashboardSelector,
  TRTCTable,
  TablePagination,
 },
 data() {
  return {
   isLoading: false,
   fullPage: true,
   search: "",
   selectedElement: "",
   selectedQueue: "",
   period: "",
   selectHostname: "",
   trtcData: undefined,
   didNumbers: [],
   showTop: "top10",
   textMonth: "",
   buttonSelected: "month",
  };
 },
 methods: {
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  getButtonSelectedFromQueryPeriod() {
   let period = undefined;
   if (this.$route.query.period) {
    period = this.$route.query.period;
   }
   if (period) {
    if (period.length == 8) {
     this.buttonSelected = "day";
    } else if (period.length == 6) {
     this.buttonSelected = "month";
    } else {
     this.buttonSelected = "week";
    }
   }
  },
  async getDidNumbers() {
   let didNumbers = [];
   let url = `https://expert-stats-api.bluerocktel.net/api/v1/${this.hostName}/dids?period=${this.period}`;

   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    let map =
     this.pbxMap && this.pbxMap.dids && this.pbxMap.dids.length > 0
      ? this.pbxMap.dids
      : this.getPbxMap().dids;
    let data = res.data;
    for (let index = 0; index < map.length; index++) {
     const element = map[index];
     data.forEach((res) => {
      if (element.includes(res) && didNumbers.indexOf(element) < 0) {
       didNumbers.push(element);
      }
     });
    }
    return didNumbers;
   } catch (error) {
    this.errorHandling(error);
   }
   this.$emit("setLoading", false);
  },
  async getDidNumberCallers(number) {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/pbxData/${
      this.hostName
     }/didNumberCallers?&did_number=${number}&period=${this.period}`
    );

    return res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getNumbersObj() {
   this.isLoading = true;
   let data = await this.getDidNumbers();
   this.didNumbers = data;
   console.log("file: ExpertStatisticsDID.vue:170 ~ getNumbersObj ~ data:", data);
   let mapData = data.map((res) => {
    return res.split(" ")[0];
   });

   console.log("file: ExpertStatisticsDID.vue:176 ~ getNumbersObj ~ mapData:", mapData);
   let dataArr = [];
   let dataObj = {};

   mapData.forEach((el) => {
    dataObj = {};
    dataObj["value"] = el;
    dataObj["label"] = el;
    dataObj["type"] = "didNumber";
    dataArr.push(dataObj);
   });
   console.log("file: ExpertStatisticsTRTC.vue ~ line 96 ~ data.forEach ~ dataArr", dataArr);
   this.isLoading = false;
  },
  async getDidNumberCallersObj(event) {
   this.isLoading = true;
   let data = await this.getDidNumberCallers(event);
   console.log("file: ExpertStatisticsTRTC.vue ~ line 119 ~ getDidNumberCallersObj ~ data", data);
   let dataArr = [];
   let dataObj = {};
   let total = 0;
   let trtcObj = {};

   let dataKeys = Object.keys(data);

   dataKeys.forEach((el) => {
    total = total + parseInt(data[el]);
    dataObj = {};
    dataObj["number"] = el;
    dataObj["calls"] = parseInt(data[el]);
    dataArr.push(dataObj);
   });

   trtcObj["data"] = this.sortArrObj(dataArr);
   trtcObj["total"] = total;

   this.trtcData = trtcObj;
   this.isLoading = false;
   console.log(
    "file: ExpertStatisticsTRTC.vue ~ line 142 ~ getDidNumberCallersObj ~ trtcObj",
    trtcObj
   );
  },
  filteredData() {
   if (this.trtcData) {
    return this.trtcData.data.filter((data) => {
     return data.number.toLowerCase().includes(this.search.toLowerCase());
    });
   }
  },
  sortArrObj(arr) {
   let sortedArr = [];

   sortedArr = arr.sort((a, b) => {
    return b.calls - a.calls;
   });

   return sortedArr;
  },
  getAllElementsFromKeys(keys) {
   let elementsArr = [];
   keys.forEach((el, index) => {
    let elSplit = el.split(":");
    let element = elSplit[0];
    if (!elementsArr.includes(element) && element.length > 0) {
     elementsArr.push(element);
    }
   });
   return elementsArr;
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = month + "/" + year;
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  currentMonthAndYearForPbx() {
   if (!this.period) {
    let options = { month: "short" };
    let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
    let formattedMonth = new Intl.DateTimeFormat("fr-FR", options).format(month).slice(0, -2);
    this.textMonth = formattedMonth;
    let year = new Date().getFullYear();
    this.period = String(year) + String(month);
   }
  },
 },
 watch: {
  period: function (val) {
   this.getNumbersObj();
   if (this.selectedElement) {
    this.getDidNumberCallersObj(this.selectedElement);
   }
  },
 },
 mounted() {
  this.currentMonthAndYearForPbx();
  this.getNumbersObj();
  this.getButtonSelectedFromQueryPeriod();
 },
 computed: {
  ...mapGetters(["hostName", "pbxMap"]),
 },
};
</script>

<style></style>
